import utils from '../functions/functions'
export default{
    state: {
        smi24: [],
        infox: [],
        smi2: [],
        mixed: [],
        mixedReserve: [],
        inProgress: false,
        feedSum: undefined,
        feedCount: 0,
        feedNewsOnlySum: undefined,
        feedNewsOnlyCount: 0,
        filters: {
            sources: [
                    'novorab.ru',
                    'tagilcity.ru',
                    'progoroduhta.ru',
                    'rnews.ru',
                    'novostivolgograda.ru',
                    'interesnoe-online.ru',
                    'inkazan.ru',
                    'muksun.fm',
                    'tvtomsk.ru',
                    'nova24.uz',
                    'sarinform.ru',
                    'mkset.ru',
                    'medzdrav.info',
                    'YakutiaMedia',
                    '56Orb.ru',
                    'sibkray.ru',
                    'food.ru'
                ],
            categories: [
                'health',
                'house'
            ]
            
        },

        sourceFilter: function(newsObj){
            let res = true
            for (let i=0; i<this.filters.sources.length; i++){
                if (this.filters.sources[i] === newsObj.name) {
                    res = false
                    break
                }
            }
            return res
        },
        catFilter: function(newsObj){
            let res = true
            for (let i=0; i<this.filters.categories.length; i++){
                if (this.filters.categories[i] === newsObj.category) {
                    res = false
                    break
                }
            }
            return res
        },
        getCollection1: function(newsArr){
            let arr = []
            for (let i=0; i<newsArr.length; i++){
                if (this.sourceFilter(newsArr[i])) arr.push(newsArr[i])
            }
            return  arr
        },
        getCollection2: function(newsArr){
            let arr = []
            for (let i=0; i<newsArr.length; i++){
                if (this.catFilter(newsArr[i])) arr.push(newsArr[i])
            }
            return  arr
        }
    },
    actions: {
        FAKE_RESET(context){
            context.commit('FAKE_UPDATE')
        },
        FEED_COUNT_RESET(context){
            context.commit('FEED_COUNT_UPDATE_TO_DEFAULT')
        },
        FEED_SUM_DEFINE(context, sum){
            context.commit('FEED_SUM_UPDATE', sum)
        },
        FEED_SUM_NEWSONLY_DEFINE(context, sum){
            context.commit('FEED_SUM_NEWSONLY_UPDATE', sum)
        },        
        RESET_ALL(context){
            const empty = []
            context.commit('UPDATE_ALL', empty)
        },  

        /**************************************************************************** */
        async SMI2_FETCH(context, vueContext){
            const newsBlocks = [] 
            context.commit('FETCHING_START')
            try {
                //103145
                //https://smi2.ru/data/js/103145.js
                const realFeed = `https://smi2.ru/data/js/102543.js`
                const feedUrl = (utils.localResources()) ? 'http://localhost/content/mock/smi2/' : realFeed
                const res = await fetch(feedUrl, {
                    //параметры для теста
                    // mode: 'no-cors'
                })
                if (!res.ok){
                    utils.safeGoal('ErrorSmi2', vueContext)
                }
                const blocks = (res.ok) ? await res.json() : {news:[]}
                for (let i=0; i<blocks.news.length; i++){
                    newsBlocks[i] = blocks.news[i]
                    newsBlocks[i].name = 'smi2.ru'
                    const imgMock = 'http://localhost/content/mock/smi2/image/'
                    newsBlocks[i].img = (utils.localResources()) ? imgMock : newsBlocks[i].img
                }
            }catch(e){
                console.log('catched', e)
                utils.safeGoal('ErrorSmi2', vueContext)                
            }
            let shuffled = utils.shuffleArray(newsBlocks)
            // const max = (utils.isRotator(vueContext)) ? newsBlocks.length : 5
            const max = newsBlocks.length

            const result = {
                array: shuffled.slice(0, max),
                name: 'smi2',
                vueContext: vueContext
            }
            context.commit('UPDATE_FEED', result)
            context.commit('FETCHING_END')
        },
        async FETCH_SMI24(context, vueContext){
            context.commit('FETCHING_START')
            const newArr = []
            try {
                const smi24ID = (utils.isRotator(vueContext)) ? '29912' : '29691'
                const urlBase = '//data.24smi.net/informer?object='
                const feedUrl = (utils.localResources()) ? 'http://localhost/content/mock/smi24/' : `${urlBase}${smi24ID}&output=json&num=50`
                const res = await fetch(feedUrl, {})
                const blocks = (res.ok) ? await res.json() : {news:[]}
                if (!res.ok){
                    utils.safeGoal('Error24smi', vueContext)
                }
                if (blocks.news.length){
                    // const max = (utils.isRotator(vueContext)) ? blocks.news.length : (blocks.news.length - 10)
                    const max = blocks.news.length
                    // const max = (utils.isPost(vueContext)) ? 10 : blocks.news.length
                    for (let i=0; i<max; i++){
                        if (blocks.news[i].name){
                            // console.log('blocks.news[i].name', blocks.news[i].name)
                            // if (blocks.news[i].name === '' || !blocks.news[i].name) console.log('NO NAME !!!!!!!!!!!!!!!!!!!!!')
                            // console.log('blocks.news[i].title', blocks.news[i].title)
                            blocks.news[i].img = (utils.localResources()) ? 'http://localhost/content/mock/smi24/image/' : blocks.news[i].img
                            blocks.news[i].rgb = blocks.news[i].icp
                            newArr.push(blocks.news[i])
                        }
                    }
                }                 
            }catch(e){
                console.log('catched', e)
                utils.safeGoal('Error24smi', vueContext)
            }
            const result = {
                array:  newArr, 
                name: 'smi24',
                vueContext: vueContext,
                df: vueContext.dfTrigger,
            }
            context.commit('UPDATE_FEED', result)
            context.commit('FETCHING_END')    
        },
        /**************************************************************************** */
        async FETCH_INFOX(context, vueContext){
            context.commit('FETCHING_START')
            const newArr = []
            try {
                const infoxID = (utils.isBack || utils.isTopen || utils.isWopen) ? '29809' : '29808'
                // const infoxIdPWA = (utils.isBack) ? '29809' : '19005'
                // const infoxID = (utils.isRotator(vueContext)) ? '19005' : '29808'
                // const infoxID = (utils.isRotator(vueContext)) ? infoxIdPWA : '29808'
                const realFeed = `//rb.infox.sg/json?id=${infoxID}&external=true`
                const feedUrl = (utils.localResources()) ? 'http://localhost/content/mock/infox/' : realFeed
                const res = await fetch(feedUrl, {
                    // credentials: 'omit'
                    // credentials: 'include', //same-origin (by default)
                    credentials: (utils.isProd()) ? 'include' : 'same-origin'                    
                })
                const blocks = (res.ok) ? await res.json() : []
                if (!res.ok){
                    utils.safeGoal('ErrorInfox', vueContext)
                }
                // const max = (utils.isRotator(vueContext)) ? (blocks.length - 10) : 5
                const max = (utils.isRotator(vueContext)) ? (blocks.length - 14) : (blocks.length - 14)
                for (let i=0; i<max; i++){
                    const img = new Image()
                    const imgMock = 'http://localhost/content/mock/infox/image/'                    
                    img.async = true
                    img.src = (utils.localResources()) ? imgMock+'?zero_pixel' : blocks[i].zero_pixel                    
                    // blocks[i].url = (utils.isProd()) ? blocks[i].url : 'https://infoxasdfsadfsdfads.com'
                    // blocks[i].url = blocks[i].url
                    blocks[i].name = blocks[i].source
                    blocks[i].img = (utils.localResources()) ? imgMock : blocks[i].img
                    blocks[i].rgb = blocks[i].bg_rgb 
                    newArr.push(blocks[i])
                }
            } catch(e) {
                console.log('error', e)
                utils.safeGoal('ErrorInfox', vueContext)
            }
            const result = {
                array:  newArr, 
                name: 'infox',
                vueContext: vueContext
            }
            context.commit('UPDATE_FEED', result)
            context.commit('FETCHING_END')   
        },
    },
    mutations: {
        FEED_COUNT_UPDATE_TO_DEFAULT(state){
            state.feedCount = 0
        },
        FEED_SUM_UPDATE(state, sum){
            state.feedSum = sum
        },
        FEED_SUM_NEWSONLY_UPDATE(state, sum){
            state.feedNewsOnlySum = sum
        },
        UPDATE_ALL(state, val){
            state.mixed = val
            state.infox = val
            state.smi24 = val
            state.smi2 = val
        },
        UPDATE_FEED(state, result){
            state[result.name] = result.array
            if (result.name === 'smi24') state[result.name] = ((!result.df) ? state.getCollection1(result.array) : result.array)
            if (result.name === 'infox') state[result.name] = state.getCollection2(result.array)
            // const max = ((result.name === 'infox' || result.name === 'smi24') && !utils.isRotator(result.vueContext)) ? (result.array.length - 10) : result.array.length                 
            const max = result.array.length                 
            state[result.name] = state[result.name].slice(0, max)
            state.mixed = state.mixed.concat(state[result.name])
            state.feedCount = (!['luckyShop', 'adProfex', 'gnezdo'].includes(result.name)) ? state.feedCount + 1 : state.feedCount
            state.feedNewsOnlyCount = (!['luckyShop', 'adProfex', 'gnezdo'].includes(result.name)) ? (state.feedNewsOnlyCount + 1) : state.feedNewsOnlyCount 
        },
        FETCHING_START(state){
            state.inProgress = true
        },
        FETCHING_END(state){
            state.inProgress = false
        },
        FAKE_UPDATE(state){
            state.inProgress = true
            state.mixedReserve = state.mixed
            state.mixed = []
            state.newsOnly = []
            const updateTime = utils.randomInt(300, 1500)
            window.setTimeout(function(){
                state.mixed = state.mixedReserve
                state.inProgress = false
            }, updateTime)
        }
    },
    getters: {
        MIXED(state){
            return state.mixed
        },
        SMI24(state){
            return state.smi24
        },
        SMI2(state){
            return state.smi2
        },        
        INFOX(state){
            return state.infox
        },
        REFRESHING(state){
            return state.inProgress
        },
        MIXED_READY(state){
            return (state.feedCount === state.feedSum && state.mixed.length && !state.inProgress)
        },
        NEWSONLY_READY(state){
            return (state.feedNewsOnlyCount === state.feedNewsOnlySum && state.newsOnly.length && !state.inProgress)
        },  
    }
}